@import 'src/styles/library/index';

.SeatComponent {
    --cardWidthMultiplier: calc(var(--tableCardWidthMultiplier, 1) * var(--seatCardWidthMultiplier, 1));
    --cardWidth: calc(var(--cardBaseWidth) * var(--cardWidthMultiplier));
    --cardHeight: calc(var(--cardBaseHeight) * var(--cardWidthMultiplier));
    --seatHandHeight: calc(var(--seatBaseHandHeight) * var(--cardWidthMultiplier));
    --seatComponentWidth: calc(var(--seatComponentBaseWidth) * var(--cardWidthMultiplier));


    width: var(--seatComponentWidth);

    & .seat-hand {
        --handMarginH: #{px(10)};
        --handWidth: calc(var(--seatComponentWidth) - 2 * var(--handMarginH));

        position: relative;
        z-index: $z-seat + 1;
        width: var(--handWidth);
        height: var(--seatHandHeight);
        margin: {
            left: auto;
            right: auto;
        }
        overflow: hidden;

        &.isVertical {
            @include centerH;

            position: absolute;
            top: 90%;
            height: auto;
            overflow: visible;

            z-index: 301;
        }
    }

    & .tray {
        @include tray;
    }

    & .label-container {
        position: relative;
        display: flex;
        justify-content: flex-start;

        &:after {
            content: '';
        }
    }

    & .primary-label,
    & .secondary-label,
    & .tertiary-label {
        user-select: none;
    }

    & .primary-label {
        height: 1em;
        margin-bottom: px(2);
        font-weight: bold;
    }

    & .secondary-label {
        @include ellipsis;
        @include centerH;

        position: absolute;
    }

    & .tertiary-label {
        display: flex;
        align-items: center;
        height: 100%;

        &.hidden {
            display: none;
        }
    }

    & .toggleAuctionLine {
        position: relative;
        height: var(--auctionLineHeight);
    }

    &.dealer {

        & .AuctionLineComponent {

            & .AuctionComponent {

                &:first-child {
                    @include dealerUnderline(px(5));
                }
            }
        }
    }

    &.center-seat {
        width: seatSize('centerSeat');
    }
}
