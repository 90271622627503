@import 'src/styles/library/index';

.RootComponent {
    width: 100%;
    height: 100%;
    display: flex;

    & .TableComponent {
        flex: 0 1 100%;
        height: 100%;
        max-height: 100%;
        min-height: 100%;
    }

    & .WalkThroughComponent {
        flex: 0 0 var(--walkThroughColumnWidth);
        height: 100%;
        max-height: 100%;
        min-height: 100%;
    }

    & .BiddingLadderComponent {
        flex: 0 0 auto;
        height: 100%;
        max-height: 100%;
        min-height: 100%;
        overflow: hidden;
    }

    & .TooltipComponent {
        position: fixed;
        z-index: $z-tooltip;
        //top: 50%;
        //left: 50%;
        //transform: translateX(-50%) translateY(-50%);
        left: calc((100% - var(--walkThroughColumnWidth)) / 2);
        top: 20%;
        width: 30%;
        transform: translateX(-50%);
    }
}
