@mixin button {
    //@include getFontSize(1.2);

    position: relative;
    padding: var(--buttonPaddingV) calc(1.5 * var(--buttonPaddingH));
    cursor: pointer;
    border-width: 1px;
    border-color: #606060;
    color: black;
    border-radius: 0.3em;
    background: rgb(202, 204, 207);
    overflow: hidden;
    white-space: nowrap;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .25) 100%);
    }

    @media (hover: hover) {
        &:hover {
            background: #e6e6e6;
        }
    }

    &.highlighted {
        background: #e2e284;
    }

    @include breakpointBelow(900px) {
        font-size: 17.5px;
    }
}
