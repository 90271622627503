.App {
    --buttonPaddingV: .25em;
    --buttonPaddingH: .4em;

    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    & .RootComponent {
        min-height: 0;
    }

    & .FooterComponent {
        min-height: 0;
    }

    & .WalkThroughComponent,
    & .AuxiliaryMessageComponent,
    & .bridge-table-html {
        font-family: 'Noto Sans', sans-serif;
    //    @import 'src/styles/legacy-styles';
         @import 'src/styles/overwrite-legacy-styles';
    }

    & .WalkThroughComponent {
        font-size: 1rem;
        @import 'src/styles/overwrite-legacy-WalkThroughComponent';
    }

    & .AuxiliaryMessageComponent {
        line-height: 110%;
        @import 'src/styles/overwrite-legacy-styles-AuxiliaryMessageComponent';
    }

    & .bridge-table-html {
        overflow: hidden;
        @import 'src/styles/overwrite-legacy-styles-bridge-table-html';
    }

}
