@import 'src/styles/library/index';

.CardComponent {

    & .card {
        width: var(--cardWidth);
        height: var(--cardHeight);
        border-radius: px(8);
        background: {
            size: 100% 100%;
            color: white;
        }
        cursor: not-allowed;

        &.face {
            border: 1px solid black;
            box-shadow: 0 0 px(3) rgba(0, 0, 0, 0.5);
        }

        &.highlighted {
            background: {
                color: rgb(253, 247, 157);
            }
        }

        &.interactive {
            cursor: pointer;
        }
    }

    & .noBackground{
        visibility: hidden;
        background: transparent;
    }
}

