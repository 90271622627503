@mixin centerV {
    transform: translateY(-50%);
    top: 50%;
}

@mixin centerH {
    transform: translateX(-50%);
    left: 50%;
}

@mixin centerVH {
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
}

@mixin centerHV {
    @include centerVH;
}
