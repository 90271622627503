@import 'src/styles/library/index';

.TableComponent {
    $outerColor: #007d2a;
    $innerColor: #029e38;

    --breakpointCardWidthMultiplier: 1;
    --tableCardWidthMultiplier: calc(var(--tableBaseCardWidthMultiplier) * var(--breakpointCardWidthMultiplier));

    // card & hand
    --cardBaseWidth: #{px(90)};
    --cardBaseHeight: calc(var(--cardBaseWidth) * 328 / 227);
    --seatBaseHandHeight: calc(var(--cardBaseHeight) * 0.67);
    --verticalOverlap: -0.76;

    // tray
    --trayPadding: #{px(5)};
    --trayFontSize: #{px(30)};

    --auctionLineHeight: #{px(35)};
    --auctionLineHeight-top-height: #{auctionHeight('m')};
    --auctionLineHeight-right-height: #{auctionHeight('m')};
    --auctionLineHeight-bottom-height: #{auctionHeight('m')};
    --auctionLineHeight-left-height: #{auctionHeight('m')};
    --trayHeight: calc(2 * var(--trayPadding) + var(--trayFontSize) + var(--auctionLineHeight));
    --trayBoxShadow: #{px(3)};

    // seat
    --seat-component-top-width: #{seatSize('m')};
    --seat-component-right-width: #{seatSize('m')};
    --seat-component-bottom-width: #{seatSize('m')};
    --seat-component-left-width: #{seatSize('m')};
    --seatHeight: calc(var(--trayHeight) + var(--seatBaseHandHeight));
    --seatMargin: #{px(5)};
    --lrVerticalTop: 50%;

    // table info
    --tableInfoMargin: #{px(20)};

    // auxiliary messages
    --auxiliaryMarginH: var(--seatMargin);
    --auxiliaryMarginV: var(--seatMargin);

    position: relative;
    background: $outerColor;
    background: radial-gradient(circle, $innerColor 0%, $outerColor 60%);

    & .close-trick {
        width: 100%;
        height: 100%;
    }

    & .SeatComponent {

        position: absolute;
        z-index: $z-seat;

        &.seat-position-top {
            @include centerH;
            --auctionLineHeight: var(--auctionLineHeight-top-height);

            --seatComponentBaseWidth: var(--seat-component-top-width);

            top: var(--seatMargin);
        }

        &.seat-position-right {
            @include centerV;
            --auctionLineHeight: var(--auctionLineHeight-right-height);

            --seatComponentBaseWidth: var(--seat-component-right-width);

            top: var(--lrVerticalTop);
            right: var(--seatMargin);
        }

        &.seat-position-bottom {
            @include centerH;

            --auctionLineHeight: var(--auctionLineHeight-bottom-height);
            --seatComponentBaseWidth: var(--seat-component-bottom-width);

            z-index: $z-seat + 1;
            bottom: var(--seatMargin);
        }

        &.seat-position-left {
            @include centerV;
            --auctionLineHeight: var(--auctionLineHeight-left-height);
            --trayHeight: calc(2 * var(--trayPadding) + var(--trayFontSize) + var(--auctionLineHeight));

            --seatComponentBaseWidth: var(--seat-component-left-width);

            top: var(--lrVerticalTop);
            left: var(--seatMargin);
        }

        &.seat-position-top.isVertical {

            & ~ .seat-position-right.isVertical,
            & ~ .seat-position-left.isVertical {
                --lrVerticalTop: 45%;
            }
        }

        &.seat-position-top:not(.isVertical) {

            & ~ .seat-position-right.isVertical,
            & ~ .seat-position-left.isVertical {
                top: calc(var(--seatHeight) + calc(var(--dummyVerticalMargin) * var(--seatMargin)));
                transform: none;
            }
        }
    }

    & .center-seat {
        &.seat-position-top {
            @include centerVH;
        }
    }

    & .ClaimedTricksComponent {
        position: absolute;
        bottom: var(--seatMargin);
        right: 0;
        padding: {
            right: var(--seatMargin);
            left: var(--seatMargin);
        }
    }

    & .TableInfoComponent {
        position: absolute;
        top: calc(var(--tableInfoMargin) * 2);
        max-width: calc((100% - var(--seat-component-top-width)) / 2 - 4 * #{var(--tableInfoMargin)});

        &.left {
            left: var(--tableInfoMargin);
        }

        &.right {
            right: var(--tableInfoMargin);
        }
    }

    & .AuxiliaryMessageComponent {
        position: absolute;
        z-index: $z-auxiliaryMessage;

        &.center {
            @include centerH;

            --top: calc(var(--seatHeight) + 2 * var(--auxiliaryMarginV) + var(--trayBoxShadow));

            top: var(--top);
            width: px(560);
            max-height: calc(75% - var(--seatHeight) / 8 - var(--top));
        }

        &.left,
        &.right {
            --maxHeight: calc(50% - var(--seatHeight) / 2 - 2 * var(--auxiliaryMarginV) - var(--trayBoxShadow));
            --finalBottomSeatWidth: calc(var(--seat-component-bottom-width) * var(--tableCardWidthMultiplier) * var(--bottomSeatCardWidthMultiplier));

            width: calc(50% - var(--finalBottomSeatWidth) / 2 - 2 * var(--auxiliaryMarginH));
            max-height: var(--maxHeight);
        }

        &.left {
            bottom: var(--auxiliaryMarginV);
            left: var(--auxiliaryMarginH);
        }

        &.right {
            top: calc(100% - var(--maxHeight) - var(--auxiliaryMarginV));
            right: var(--auxiliaryMarginH);
        }
    }

    & .bridge-table-html {
        position: absolute;
        z-index: $z-bridgeTableHtml;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 1em;
        overflow: auto;
    }

    &.seat-size {
        $positions: top, right, bottom, left;
        $positions: top, right, bottom, left;
        $sizes: s, m, l, d;

        @each $position in $positions {
            &-#{$position} {
                @each $size in $sizes {
                    &-#{$size} {
                        #{--auctionLineHeight-#{$position}-height}: #{auctionHeight($size)};
                        #{--seat-component-#{$position}-width}: #{seatSize($size)};
                    }
                }
            }
        }
    }

    &.bridge-table-hidden {

        & > *:not(.bridge-table-html) {
            display: none;
        }
    }

    @media(max-aspect-ratio: 1561 / 980) {
        --breakpointCardWidthMultiplier: 0.9;
    }

    /* @include breakpointBelow(630px) {
         --cardBaseWidth: 64px;
     }

     @include breakpointAbove(1600px) {
         --cardBaseWidth: 110px;
     }
     */
}
