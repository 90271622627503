@import 'src/styles/library/index';

.TricksComponent {
    --cardWidthMultiplier: calc(var(--tableCardWidthMultiplier, 1));
    --cardWidth: calc(var(--cardBaseWidth) * var(--cardWidthMultiplier));
    --cardHeight: calc(var(--cardBaseHeight) * var(--cardWidthMultiplier));

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-tricks;
    pointer-events: none;
}
