@import 'src/styles/library/index';
@include getFontSize(1.3);

& font {
    &.suitred,
    &.suitblack{
        font-size: 1.8rem;
        font-family: "Times New Roman", sans-serif;
    }
}

&.hasButtons {
    & .text p {
        text-align: center;
    }
}

& .text {
   line-height: 120%;
    text-align: left;
}

&.right {
    text-align: center;
}

& table {
    &.quiz_expl {
        border: none;
        margin: 0;
    }
}

& td.quiz_bottom {
    text-align: left;
}

