@import 'src/styles/library/index';
@import 'src/styles/reset';

$biddingLadderWidth: 68;
$bonusIndicatorWidth: 12;

html,
body,
#root,
.App {
    width: 100%;
    height: 100%;
}

html {
    $walkThroughColumnWidth: 0.335;
    $bidColumnWidth: ($biddingLadderWidth + $bonusIndicatorWidth) / 1024;

    @include contentColumns(0, 0);

    &.with-walk-through-column {
        @include contentColumns($walkThroughColumnWidth, 0);
    }

    &.with-bid-column {
        @include contentColumns(0, $bidColumnWidth);
    }

    &.with-walk-through-column-and-bid-column {
        @include contentColumns($walkThroughColumnWidth, $bidColumnWidth);
    }
}

body {
    margin: 0;
    font-family: 'Noto Sans', 'Verdana', 'Arial', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
