// usage: @include aspectRatio((16:9), false)
@mixin aspectRatio($aspectRatio, $setChildStyles: true) {
    position: relative;

    @each $width, $height in ($aspectRatio) {
        &:before {
            content: '';
            position: relative;
            display: block;
            width: 100%;
            padding-top: $height / $width * 100%;
        }

        @if ($setChildStyles) {
            & > * {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }
    }
}
