@import 'src/styles/library/index';

$shades: 0, 0.1, 0.2, 0.3;

@mixin darken {
    $length: length($shades);
    @each $shade in $shades {
        $index: index($shades, $shade);
        &:nth-child(#{$length}n+#{$index}){
            &:after {
                opacity: $shade;
            }
        }
    }
}

.AuctionLineComponent {
    @include noScrollbar;

    display: flex;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    & .AuctionComponent {
        @include darken;

        position: relative;
        flex: 0 0 px(100);
        &:not(.walkThrough){
            flex: 0 0 px(70);
       }
        height: 100%;
        border: 1px solid black;

        &:not(:first-child){
            border-left: none;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: black;
        }
    }

    & img {
        height: 100%;
        width: 100%;
        object-fit:contain;
    }

    &.hidden {
        display: none;
    }
}
