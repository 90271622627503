

& .content{
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 10px;
    font-size: 2.2rem;
    line-height: 130%;
    & table{
        &.quiz_expl {
            width: 100%;
            margin-top: 2rem;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

& font {
    &.suitred,
    &.suitblack{
        font-size: 1.3em;
        font-family: 'Times New Roman', sans-serif;
    }
}


& header,
& pheader {
    font-size: 2.2rem;
    line-height: 120%;
    & p{
        font-size: .9em;
        font-weight: 500;
        padding: 0;
        margin-bottom: 1em;
        color: #666;
        display: block;
        font-variant: small-caps;
        line-height: 90%;
        margin-top: 2rem;
        text-align: left;
        text-transform:uppercase;
    }
}

& subheader {
    font-size: .9em;
    font-weight: 500;
    padding: 0;
    & p{
        margin:0;
    }
}

& img{
    &.panelist {
        margin: 0;
        height: 8em;
        padding: 0;
        padding-top: 5px;
    }
}

& p {
    margin: 1.2em 0;
    line-height: 130%;
}

& ul {
    list-style-type: disc;
    margin-left: 30px;
}

& table{
    &.quiz_expl {
        width:100%;
        background-image: -webkit-gradient(
                linear,
                left bottom,
                right bottom,
                color-stop(0, rgb(234, 234, 254)),
                color-stop(1, rgb(204, 204, 254))
        );
        border: thin;
        border-style: solid;
        border-bottom-style: none;
        border-color: #cccccc;
    }
    &.quiz_expl_i {
        visibility: hidden;
    }
}

& td {
    &.quiz_top {
        color: #000000;
    }

    &.quiz_top_green {
        color: #390;
    }

    &.quiz_top_red {
        color: red;
    }
}

& table.poll {
    font-size: 1.2em;
    line-height: 130%;
    margin-right: auto;
    margin-left: auto;

    & font {
        &.suitred,
        &.suitblack{
            font-size: 3rem;
            font-family: 'Times New Roman', sans-serif;
        }
    }

    & li {
        list-style-type: none;
    }

    & td {
        text-align: center;
        width: 30%;
        white-space: nowrap;
    }

    & tr.poll_h {
        background-color: yellow;
    }

}

td.quiz_bottom p {
    margin: 15px 0;

    padding-left: 7px;
    padding-right: 2px;
}

& div {
    font-size: 1em;
    &.quiz_expl{
        position: absolute;
        z-index: 2;
        bottom: 5px;
        left: 2px;
        right: 2px;
        margin: 2px;
        padding: 0;
    }

    &.quiz_expl_i{
        visibility: hidden;
    }

    & table{
        &.quiz_expl {
            padding: 0;
            margin: 0;
        }
        &.quiz_expl_i {
            visibility: hidden;
        }
    }

    & td{
        &.quiz_top {
            padding: 10px;
            margin: 0;
            height: 20%;
            width: 100%;
            text-align: left;
        }

        &.quiz_bottom{
            margin: 15px 0;
            padding-right: 7px;
            text-align: left;
            & img {
                display: block;
                margin: auto;
            }
        }

        & subheader {
            font-size: 1em;
            padding: 0;
            text-align: center;
            align-content: center;
            padding-bottom: 10px;
            margin: .3rem 0 0 0;
        }

    }

    & tr{
        &.quiz_top {
            background-image: -webkit-gradient(
                    linear,
                    left bottom,
                    right bottom,
                    color-stop(0, rgb(202, 202, 254)),
                    color-stop(1, rgb(79, 79, 252))
            );
            padding: 10px;
            margin: 0;
            height: 80%;
            vertical-align: middle;
            width: 100%;
            text-align: left;
            overflow: hidden;
        }
    }
}

td.quiz_bottom {
    padding: 0px;
    text-align: center;
    align-content: center;
}

