@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 100;
    src:
        local('Noto Sans'),
        url('../../../fonts/NotoSans-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 100;
    src:
        local('Noto Sans'),
        url('../../../fonts/NotoSans-ThinItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 200;
    src:
        local('Noto Sans'),
        url('../../../fonts/NotoSans-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 200;
    src:
        local('Noto Sans'),
        url('../../../fonts/NotoSans-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 300;
    src:
        local('Noto Sans'),
        url('../../../fonts/NotoSans-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 300;
    src:
        local('Noto Sans'),
        url('../../../fonts/NotoSans-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    src:
        local('Noto Sans'),
        url('../../../fonts/NotoSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 400;
    src:
        local('Noto Sans'),
        url('../../../fonts/NotoSans-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    src:
        local('Noto Sans'),
        url('../../../fonts/NotoSans-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 500;
    src:
        local('Noto Sans'),
        url('../../../fonts/NotoSans-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    src:
        local('Noto Sans'),
        url('../../../fonts/NotoSans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 600;
    src:
        local('Noto Sans'),
        url('../../../fonts/NotoSans-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    src:
        local('Noto Sans'),
        url('../../../fonts/NotoSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 700;
    src:
        local('Noto Sans'),
        url('../../../fonts/NotoSans-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 800;
    src:
        local('Noto Sans'),
        url('../../../fonts/NotoSans-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 800;
    src:
        local('Noto Sans'),
        url('../../../fonts/NotoSans-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 900;
    src:
        local('Noto Sans'),
        url('../../../fonts/NotoSans-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 900;
    src:
        local('Noto Sans'),
        url('../../../fonts/NotoSans-BlackItalic.ttf') format('truetype');
}
