$borderRadius: px(10);

// hand variables
$hOverlap: 0.87;
$cards: (1-$hOverlap) * 13 + $hOverlap;

// z-indexes
$z-table: 100;
$z-seat: $z-table + 100;
$z-tricks: $z-seat + 100;
$z-modal: 1000;
$z-auxiliaryMessage: $z-modal - 100;
$z-bridgeTableHtml: $z-modal + 100;
$z-tooltip: 2000;
$z-settings: 3000;
