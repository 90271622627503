@import 'src/styles/library/index';

.FooterComponent {
    display: grid;
    grid-template-columns: 2fr 1fr 1.1fr 1.1fr  1.1fr 1fr 1fr 1fr 1.5fr 1fr 0.7fr 0.3fr;
    column-gap: 0.4em;
    background: rgb(241, 236, 234);
   // font-size: px(20);
    padding: var(--buttonPaddingV) var(--buttonPaddingH);
    color: black;

    & > div {
        justify-self: center;
        width: 100%;

        &:first-child {
            justify-self: start;
        }
    }

    & .settings,
    & .card-button {
        position: relative;
        width: 100%;
    }

    & img {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        cursor: pointer;
    }

    & .ButtonComponent {
        width: 100%;
        font-size: 20px;
        display: block;
    }
}

